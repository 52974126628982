import { useEffect, useState } from "react"
import axios from 'axios';
import { range } from "d3";

function parseCSV(csvText: string) {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0].split(','); // Extract headers (assumes the first row is the header row)
    const data = []; // Initialize an array to store parsed data
    for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(','); // Split the row, handling '\r' characters
        const rowObject: any = {};
        for (let j = 0; j < headers.length; j++) {
            rowObject[headers[j]] = rowData[j];
        }
        data.push(rowObject);
    }
    return data;
}


export default function Articles() {
    const [csvData, setCsvData] = useState([] as any);
    useEffect(() => {
        const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQaKTQfG17fFGbRzFXca7nf2ERi5xP5WxAdlJgQhs54H7pu3ecUcmyQ6HQ1zDQ-KYeWoP3wpSZnAzXM/pub?output=csv'; // Replace with your Google Sheets CSV file URL
        axios.get(csvUrl)
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);
                setCsvData(parsedCsvData);
                console.log(parsedCsvData);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }, []);

    return (
        <div className='articles'> {
            csvData.map((row: any, i: number) => (
                <button 
                    className='article' 
                    key={i}
                    onClick={() => {
                        window.location.href = row.ARTICLE;
                    }}>
                    <img className="article-image" src={row.IMAGE}></img>
                    <div className='article-title'>{row.DESCRIPTION}</div>
                </button>
            ))}
        </div>
    )
}